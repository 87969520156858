import React, { useState, Fragment } from 'react';
import { TextBlock, Snackbar, Heading, Button, Link } from 'neon-design-system';
import { Grid } from '@material-ui/core';
import { useAuth0 } from 'neon-auth0';
import { useWebAuth } from '../../helpers/hooks/useWebAuth';
import { config } from '../../config';

export const Security = () => {
  const auth0 = useAuth0();
  const authClient = useWebAuth();
  const [success, setSuccess] = useState(false);

    const resetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        authClient?.changePassword({
            connection: 'Username-Password-Authentication',
            email: auth0.user?.email || '',
        }, (e, r) => setSuccess(true));
    }

  if (auth0.user?.email?.toLowerCase().includes('@marexspectron.com') || auth0.user?.email?.toLowerCase().includes('@marex.com')) {
    return (
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <TextBlock>To change your Marex user account password, please contact your IT Admin.</TextBlock>
        </Grid>
      </Grid>
    );
  }

  return (<Fragment>
    <Snackbar
      open={success}
      message="Message with instructions on how to reset your password sent."
      type="success"
      onClose={() => setSuccess(false)}
      autoHideDuration={60 * 1000}
    />
    <Grid item xs={12} container spacing={4}>
      <Grid item xs={12}>
        <Grid item xs={12} container spacing={2} style={{ maxWidth: 384 }}>
          <Grid item xs={12}>
            <Heading styleName="headline1">Reset your password</Heading>
          </Grid>
          <Grid item xs={12}>
            <TextBlock styleName="caption1">You will receive a message with a link to follow so you can set a new password for your account.</TextBlock>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button colorType="primary" size="large" onClick={resetPassword}>Send link</Button>
      </Grid>
      <Grid item xs={12}>
          <TextBlock styleName="footnote" color="grey" ink="muted">
          If you need further assistance, please contact <Link ignoreRouter to={`mailto:${config.supportEmail}`}>Neon Support.</Link>
          </TextBlock>
      </Grid>
    </Grid>
  </Fragment>);
};

export default Security;
